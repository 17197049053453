import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
<path d="M5775 10544 c-55 -7 -140 -17 -190 -23 -92 -10 -228 -47 -301 -82
-23 -10 -48 -19 -56 -19 -8 0 -38 -11 -66 -25 -29 -14 -60 -25 -71 -25 -27 0
-75 -22 -175 -79 -48 -28 -93 -51 -101 -51 -7 0 -15 -7 -19 -15 -3 -8 -13 -15
-23 -15 -21 0 -103 -54 -152 -99 -18 -17 -39 -31 -47 -31 -8 0 -14 -4 -14 -10
0 -5 -11 -12 -25 -16 -14 -3 -33 -16 -43 -28 -9 -12 -42 -41 -72 -66 -30 -25
-56 -48 -58 -52 -2 -5 -12 -8 -22 -8 -10 0 -21 -9 -25 -19 -6 -20 -123 -141
-136 -141 -4 0 -18 -19 -32 -42 -14 -24 -50 -69 -81 -101 -31 -32 -63 -75 -72
-95 -8 -20 -28 -50 -43 -67 -37 -39 -73 -98 -132 -215 -27 -52 -57 -111 -68
-130 -44 -77 -78 -158 -85 -200 -4 -24 -13 -58 -20 -74 -41 -93 -97 -308 -121
-468 -14 -87 -16 -160 -12 -390 2 -156 9 -299 14 -318 6 -19 16 -66 23 -105
15 -86 51 -213 90 -323 17 -46 30 -91 30 -101 0 -19 40 -119 62 -156 45 -76
108 -202 108 -217 0 -10 3 -18 8 -18 4 0 16 -17 27 -37 11 -20 37 -57 58 -82
22 -24 50 -68 64 -98 14 -29 29 -53 33 -53 4 0 32 -30 61 -66 109 -134 359
-373 443 -424 45 -26 57 -35 90 -65 34 -31 104 -75 119 -75 7 0 29 -12 48 -26
19 -14 64 -41 102 -60 37 -19 67 -38 67 -42 0 -4 21 -13 48 -20 61 -16 81 -24
172 -62 146 -61 246 -92 320 -100 41 -5 102 -13 135 -20 192 -35 239 -40 445
-40 187 0 234 3 360 26 80 14 179 30 220 34 67 8 158 33 215 59 11 5 57 24
103 42 45 17 85 36 88 40 3 5 18 9 33 9 31 0 73 17 136 55 22 14 33 25 25 25
-9 0 -24 -6 -35 -14 -11 -7 -45 -21 -75 -30 -30 -9 -63 -21 -72 -27 -10 -5
-33 -9 -53 -9 -19 0 -67 -8 -107 -19 -148 -38 -226 -51 -358 -62 -134 -11
-414 -5 -515 10 -105 17 -270 53 -279 62 -6 5 -31 9 -58 9 -31 0 -83 14 -155
42 -59 22 -110 44 -113 48 -3 4 -14 9 -25 13 -82 24 -262 110 -286 137 -8 9
-50 34 -92 54 -42 21 -79 42 -83 47 -3 5 -44 38 -90 73 -182 138 -311 264
-443 436 -32 42 -66 82 -75 89 -10 8 -31 41 -47 74 -16 33 -40 71 -52 83 -12
12 -39 55 -59 96 -50 100 -148 314 -148 324 0 5 -11 32 -25 59 -13 28 -25 65
-25 83 0 18 -11 72 -24 120 -59 207 -70 307 -71 592 0 174 4 289 13 335 25
140 35 181 58 259 13 44 24 94 24 113 0 34 67 215 82 221 4 2 8 9 8 15 0 6 15
45 34 87 18 41 44 99 57 128 13 30 32 64 44 76 23 26 42 54 52 76 4 8 13 21
20 28 7 7 13 20 13 29 0 8 19 38 43 66 23 29 49 61 57 71 8 11 30 34 48 51 17
17 32 38 32 46 0 17 94 116 118 123 10 3 26 20 36 36 21 36 88 101 119 115 12
6 38 26 57 45 44 43 68 62 117 93 125 79 189 121 193 127 9 14 85 50 103 50
11 0 31 8 45 19 53 37 254 122 325 137 39 8 115 28 167 44 103 31 212 52 361
70 105 13 441 8 544 -9 152 -24 155 -24 75 4 -41 15 -113 30 -160 35 -47 5
-143 16 -215 24 -156 19 -448 19 -590 0z"/>
<path d="M6205 10033 c-75 -6 -270 -36 -280 -43 -5 -4 -30 -11 -55 -15 -25 -4
-68 -18 -97 -31 -28 -13 -61 -24 -72 -24 -12 0 -21 -4 -21 -10 0 -5 -13 -10
-29 -10 -19 0 -55 -17 -98 -47 -105 -72 -112 -77 -113 -90 0 -6 -8 -14 -19
-18 -31 -10 -111 -97 -142 -155 -16 -30 -33 -62 -39 -70 -19 -31 -54 -117 -72
-180 -33 -111 -40 -162 -36 -245 4 -71 10 -91 52 -177 27 -55 58 -103 71 -112
14 -9 25 -22 25 -29 0 -22 70 -97 110 -117 21 -11 53 -33 71 -50 19 -16 39
-30 46 -30 7 0 13 -3 13 -8 0 -4 13 -13 29 -20 16 -7 39 -22 51 -32 13 -11 36
-20 52 -20 15 0 68 -18 117 -40 49 -22 94 -40 101 -40 7 0 26 -7 43 -15 55
-25 189 -58 267 -66 131 -12 233 -29 310 -49 154 -40 219 -59 247 -73 15 -8
53 -20 83 -27 54 -13 210 -76 220 -89 10 -13 120 -71 134 -71 8 0 28 -12 43
-26 15 -15 45 -37 65 -50 66 -42 204 -172 258 -244 72 -95 140 -205 140 -227
0 -14 13 -53 29 -87 63 -133 97 -350 82 -538 -5 -64 -12 -121 -15 -127 -14
-22 5 -10 34 22 16 17 34 48 40 67 5 19 28 64 50 100 22 36 40 69 40 74 0 5
14 37 30 71 73 150 73 551 0 705 -34 72 -30 67 -82 142 -21 30 -38 61 -39 69
0 8 -38 48 -84 89 -46 41 -84 80 -84 86 -1 6 -13 16 -27 23 -26 11 -103 72
-155 123 -15 14 -42 29 -59 33 -17 4 -54 23 -83 42 -28 19 -76 47 -104 60 -29
14 -53 29 -53 33 0 4 -27 13 -59 18 -33 6 -79 19 -103 30 -23 10 -63 24 -88
31 -25 7 -53 17 -63 22 -32 17 -272 68 -347 74 -124 10 -303 53 -505 120 -55
18 -113 36 -130 40 -64 15 -134 55 -230 131 -11 9 -31 20 -45 25 -17 6 -39 35
-70 92 -42 77 -45 86 -44 157 0 63 6 87 34 150 19 41 38 77 42 78 4 2 8 9 8
16 0 27 116 125 190 159 207 96 404 135 625 124 106 -5 295 -34 305 -46 3 -4
28 -12 55 -19 28 -7 83 -29 123 -48 40 -18 83 -34 95 -34 13 0 32 -7 42 -16
25 -20 170 -114 205 -134 49 -27 118 -84 134 -111 9 -15 31 -34 50 -43 34 -16
79 -63 126 -130 14 -19 30 -36 35 -36 15 0 95 -112 95 -132 0 -10 8 -26 18
-36 19 -19 87 -170 97 -214 5 -24 10 -28 42 -28 l36 0 -7 68 c-4 37 -11 200
-16 362 -5 162 -14 349 -20 415 -6 66 -11 140 -13 165 l-2 44 -90 34 c-49 18
-115 40 -145 49 -30 8 -77 22 -105 30 -27 8 -77 19 -110 23 -33 5 -80 13 -105
19 -25 5 -90 19 -145 31 -338 72 -671 103 -910 83z"/>
<path d="M4720 9237 c0 -8 -18 -50 -40 -95 -22 -44 -40 -94 -40 -110 0 -16 -9
-54 -20 -86 -46 -135 -46 -367 0 -489 11 -29 20 -67 20 -84 0 -18 4 -33 8 -33
4 0 24 -28 45 -63 20 -35 53 -76 72 -93 19 -16 35 -34 35 -39 0 -12 102 -115
114 -115 4 0 28 -17 54 -39 55 -45 174 -121 191 -121 11 0 111 -50 121 -60 3
-3 37 -19 75 -34 39 -16 75 -33 82 -38 6 -5 39 -13 73 -19 35 -5 75 -16 91
-24 15 -8 36 -15 45 -15 10 0 31 -4 48 -9 40 -13 124 -34 266 -67 63 -15 137
-36 165 -46 27 -10 66 -18 85 -18 19 0 73 -14 120 -30 47 -17 115 -41 151 -54
37 -13 78 -31 91 -40 14 -9 47 -22 74 -29 40 -11 166 -87 234 -142 47 -38 131
-128 146 -156 26 -48 26 -276 1 -311 -10 -12 -28 -39 -41 -59 -66 -99 -272
-279 -319 -279 -10 0 -42 -12 -71 -26 -64 -32 -273 -74 -371 -74 -198 0 -556
106 -648 192 -16 15 -50 38 -76 51 -25 13 -66 43 -91 68 -25 24 -65 62 -90 85
-25 22 -55 54 -67 70 -12 16 -47 56 -77 88 -31 33 -56 66 -56 74 0 15 -42 78
-65 97 -20 17 -102 158 -115 198 -7 22 -27 66 -46 99 -19 33 -37 71 -40 84 -8
32 -28 46 -61 42 -28 -3 -28 -4 -27 -68 0 -87 13 -452 25 -728 5 -123 9 -267
9 -321 l0 -97 58 -18 c31 -10 77 -26 102 -36 86 -35 243 -80 309 -90 36 -6 93
-17 126 -26 79 -20 181 -40 345 -66 195 -31 557 -31 710 0 171 35 222 48 272
71 26 11 59 21 74 21 32 0 179 80 234 127 21 18 45 33 53 33 15 0 73 54 169
159 55 58 108 157 108 199 0 15 10 64 21 108 29 107 32 330 5 419 -10 33 -21
78 -26 100 -10 56 -19 75 -52 115 -37 45 -39 48 -75 98 -17 23 -57 63 -88 89
-32 27 -78 65 -102 85 -24 21 -56 41 -71 45 -15 3 -43 16 -62 27 -98 60 -226
109 -339 130 -46 9 -107 23 -135 32 -53 17 -152 39 -246 54 -30 5 -108 23
-173 40 -65 16 -131 30 -147 30 -30 0 -200 56 -285 94 -27 13 -75 33 -105 45
-77 32 -200 94 -200 101 0 9 -104 80 -117 80 -18 0 -207 193 -226 231 -10 19
-32 51 -48 70 -35 39 -99 166 -99 195 0 10 -11 64 -25 119 -18 72 -25 127 -25
198 0 58 -4 97 -10 97 -5 0 -10 -6 -10 -13z"/>
<path d="M3245 5224 c-81 -17 -106 -29 -143 -67 -64 -68 -80 -153 -42 -231 31
-66 77 -90 275 -144 78 -22 108 -79 65 -122 -41 -41 -192 -21 -266 35 l-32 24
-41 -49 c-22 -27 -41 -52 -41 -57 0 -13 65 -53 132 -81 52 -21 79 -26 154 -27
81 0 96 3 149 30 50 26 63 39 88 85 70 133 0 261 -167 304 -155 41 -194 66
-181 119 14 56 121 64 215 17 l60 -30 31 42 c40 56 44 65 27 80 -28 27 -107
58 -174 68 -72 10 -77 10 -109 4z"/>
<path d="M6083 5219 c-87 -17 -144 -59 -172 -126 -19 -47 -11 -155 15 -190 30
-40 105 -78 216 -109 53 -14 103 -33 111 -43 28 -31 29 -57 5 -86 -21 -24 -30
-26 -93 -26 -61 0 -79 4 -140 36 l-70 36 -42 -47 c-47 -53 -46 -58 13 -95 99
-61 244 -84 336 -54 112 36 158 94 158 198 0 125 -46 170 -230 222 -122 35
-140 46 -140 89 0 54 26 70 109 64 43 -3 82 -13 103 -25 61 -36 65 -35 95 13
15 24 31 44 36 44 4 0 7 6 7 14 0 22 -116 74 -190 86 -72 11 -69 11 -127 -1z"/>
<path d="M8191 5214 c-62 -16 -98 -40 -141 -92 l-31 -37 43 -37 c48 -43 63
-46 87 -20 40 45 74 62 121 62 73 0 111 -53 91 -128 -12 -42 -72 -105 -201
-207 -41 -33 -81 -65 -89 -73 -8 -7 -24 -20 -37 -29 -21 -13 -24 -23 -24 -79
l0 -64 260 0 260 0 0 65 0 64 -152 3 -153 3 25 24 c14 13 41 33 60 46 67 44
161 138 185 186 68 133 -3 288 -146 318 -64 14 -91 13 -158 -5z"/>
<path d="M3910 5193 c-12 -28 -137 -320 -231 -543 -27 -63 -49 -121 -49 -128
0 -10 18 -12 72 -10 l72 3 32 75 31 75 154 0 153 0 31 -75 31 -75 77 -3 c42
-2 77 1 77 6 0 9 -23 66 -57 141 -9 19 -28 64 -43 100 -16 36 -49 116 -75 176
-26 61 -63 149 -83 198 l-36 87 -71 0 c-69 0 -72 -1 -85 -27z m130 -268 c22
-54 40 -103 40 -109 0 -14 -167 -19 -175 -5 -11 16 79 228 91 216 2 -2 22 -48
44 -102z"/>
<path d="M4465 5208 c-3 -7 -4 -166 -3 -353 l3 -340 215 0 c201 0 218 1 263
22 52 24 62 32 95 80 48 68 20 182 -56 233 -20 13 -39 26 -41 27 -3 2 9 18 27
36 91 95 62 229 -63 283 -54 24 -432 34 -440 12z m391 -152 c8 -11 14 -30 14
-42 0 -65 -37 -84 -165 -84 l-95 0 0 73 c0 41 2 76 4 78 2 2 55 2 116 -1 96
-4 114 -8 126 -24z m30 -281 c34 -34 30 -80 -9 -109 -24 -18 -45 -21 -148 -24
l-119 -4 0 81 0 81 125 0 c121 0 127 -1 151 -25z"/>
<path d="M5197 5213 c-4 -3 -7 -163 -7 -355 l0 -348 75 0 75 0 0 115 0 115 63
0 64 0 77 -115 76 -115 85 0 c47 0 85 2 85 4 0 2 -36 57 -80 121 -44 64 -80
120 -80 123 0 4 18 17 40 30 54 30 86 77 99 143 29 141 -45 252 -184 278 -64
12 -377 15 -388 4z m399 -166 c17 -20 24 -40 24 -70 -1 -79 -47 -106 -182
-107 -101 0 -98 -3 -98 112 l0 100 116 -4 c113 -3 116 -4 140 -31z"/>
<path d="M6544 5206 c-12 -31 3 -490 17 -531 19 -56 105 -140 160 -157 24 -7
85 -13 134 -13 78 0 97 4 147 28 68 33 109 82 134 157 16 47 19 89 19 290 l0
235 -71 3 c-67 3 -72 1 -78 -20 -3 -13 -6 -119 -6 -237 0 -200 -1 -216 -22
-252 -55 -98 -219 -92 -265 9 -14 30 -17 81 -21 270 l-4 232 -69 0 c-51 0 -71
-4 -75 -14z"/>
<path d="M7320 4866 l0 -356 188 0 c248 0 319 18 375 91 27 37 38 96 26 149
-12 51 -28 72 -82 107 l-37 23 35 35 c41 42 49 67 43 139 -6 67 -32 104 -95
136 -45 23 -59 25 -250 28 l-203 4 0 -356z m370 204 c48 -26 46 -98 -3 -125
-25 -13 -53 -16 -127 -13 l-95 3 -3 64 c-2 35 -1 69 1 76 6 15 197 11 227 -5z
m51 -291 c15 -15 19 -30 17 -62 -5 -64 -40 -78 -183 -75 l-110 3 -3 78 -3 77
131 0 c118 0 132 -2 151 -21z"/>
<path d="M8958 5201 c-7 -10 -36 -45 -64 -77 -194 -223 -278 -322 -289 -339
-11 -16 -11 -30 -3 -67 l11 -48 158 0 c86 0 164 -3 173 -6 13 -5 16 -22 16
-81 l0 -74 73 3 72 3 3 78 c3 74 4 78 25 77 66 -6 67 -5 67 60 l0 60 -45 0
-45 0 0 215 0 215 -69 0 c-57 0 -72 -4 -83 -19z m2 -305 l0 -105 -85 -3 c-47
-2 -90 -2 -95 0 -10 4 160 212 173 212 4 0 7 -47 7 -104z"/>
<path d="M9280 5155 l0 -65 166 0 165 0 -7 -22 c-4 -13 -36 -75 -72 -138 -36
-63 -85 -153 -110 -200 -25 -47 -48 -87 -51 -90 -10 -9 -61 -109 -61 -120 0
-7 30 -10 81 -8 l81 3 94 173 c52 96 94 177 94 182 0 5 3 10 8 12 4 2 33 53
65 115 52 101 58 117 55 165 l-3 53 -252 3 -253 2 0 -65z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
